import Vue from 'vue'
import on from 'dom-event'
import Marquee3k from 'marquee3000'
import { MOBILE_BREAKPOINT } from '@/lib/consts'

export default el => new Vue({
  name: 'Marquee',
  el,
  data() {
    return {
      speed: 0,
      speedDesktop: parseFloat(el.getAttribute('data-speed')),
      speedMobile: parseFloat(el.getAttribute('data-speed-mobile')),
      key: 0,
    }
  },
  mounted() {
    this.getMarqueeSpeed()
    this.refreshOnWindowResize()

    setTimeout(() => {
      Marquee3k.init()
    })
  },
  methods: {
    getMarqueeSpeed() {
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        this.speed = this.speedMobile
      } else {
        this.speed = this.speedDesktop
      }
    },
    refreshOnWindowResize() {
      let windowWidth = window.innerWidth
      const onWindowChange = () => {
        if (window.innerWidth !== windowWidth) {
          Marquee3k.pauseAll()

          windowWidth = window.innerWidth
          this.getMarqueeSpeed()
          this.key++ // Force to create new instance of marquee root

          setTimeout(() => {
            Marquee3k.init()
          })
        }
      }
      on(window, 'resize', onWindowChange)
      on(window, 'orientationchange', onWindowChange)
    },
  },
})